import * as React from "react";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

const Layout = (props) => (
  <table cellSpacing="5" cellPadding="5" className="content">
    <tbody>
      <tr>
        <td colSpan={props.colSpan || 2} className="header">
          <Header />
        </td>
      </tr>
      {props.children}
      <tr>
        <td colSpan={props.colSpan || 2} style={{ padding: 0 }}>
          <Footer />
        </td>
      </tr>
    </tbody>
  </table>
);

export default Layout;
