import * as React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";

const siteTitle = "dichotic";

const Seo = (props) => (
  <Helmet>
    <meta charSet="utf-8" />
    <link rel="icon" href={favicon} />
    <title>{props.title ? `${props.title} | ${siteTitle}` : siteTitle}</title>
  </Helmet>
);

export default Seo;
