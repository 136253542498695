import { Link } from "gatsby";
import * as React from "react";
import SmallLogo from "../images/dichotic_logo_top_nav.gif";

const Header = () => (
  <header>
    <img
      src={SmallLogo}
      title="dichotic::frenetico death metal dalla band pi omicida, suicida e genocida del mondo!"
      alt="dichotic"
      width={113}
      height={53}
    />
    <nav>
      [
      <Link to="/" className="menu">
        &nbsp;news&nbsp;
      </Link>
      ]::[
      <Link to="/biography" className="menu">
        &nbsp;biography&nbsp;
      </Link>
      ]<br />[
      <a href="/discography" className="menu">
        &nbsp;discography&nbsp;
      </a>
      ]::[
      <a href="/photos" className="menu">
        &nbsp;photos&nbsp;
      </a>
      ]<br />[
      <a href="/contact" className="menu">
        &nbsp;contact&nbsp;
      </a>
      ]
    </nav>
  </header>
);

export default Header;
