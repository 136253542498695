import * as React from "react"

function getDaysDead() {
  const doomDateUtc = Date.UTC(2001, 6, 30)
  const now = new Date()
  const nowUtc = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())

  const deadMs = nowUtc - doomDateUtc

  // ms to days
  return deadMs / (1000 * 60 * 60 * 24)
}

const lyrics = [
  "Oh my god... What is the matter with you?",
  "Stuck in the mainstream of mediocrity",
  "Wipe that ridiculous grin from your face",
  "How can you be so accepting?",
  "Pasteurized humourless simplicity",
  "When did you cease to think for yourself?",
  "Nobody told you 90% of everything is crap",
  "Unyielding, ironclad stubbornness",
  "Differences cause disharmony in the conformist party",
  "Suppress diversity to maintain your control",
  "No explanation provided for this experience",
  "Mental note, don't panic",
  "Passionate dependency",
  "Don't panic",
  "Not panicking, not panicking",
  "Ludicrous",
  "So, what is your opinion?",
  "So what? Who asked? Irrelevant",
  "Undeterred, perpetually eager to offer up some tripe",
  "Eyes open, their vision cataract",
  "Idealism projected to the lowest common denominator",
  "Stench of moral decay masked by stupidity",
  "Reason shouted down",
  "Mob rules opinionize, opening eyes",
  "No one notices",
  "It just keeps happening",
  "Kept in the dark",
  "They never knew it anyway",
  "Horrid, wretched, vile stream of drool",
  "Fat of the land",
  "Salt of the earth",
  "Turned inside out",
  "Running Joke",
  "A total waste of effort",
  "Meant to discredit",
  "Headfirst into everything without consideration",
  "Hands held open, receiving nothing",
  "The rest have passed you by",
  "Sad reflection of wastefulness",
  "Missing, arguments confound the expression",
  "Nothing to say",
  "No one around anyway",
  "Commercial landslide victory",
  "Spoken differently in another time and space",
  "Broken pane glass diet",
  "Plastic wrapped and dispensed",
  "Packaged, sold powder form",
  "Failsafe experiment",
  "Scratched out, work of art, everything dies",
  "Overwhelmed by uncertainty",
  "Grabbing the goat by the horns, and now you proudly wear them",
  "Nothing is sacred or written in stone to preserve it",
  "Your kind is hateful and hated in return",
  "The problem for you... is that nobody cares",
  "It's a guiltless tragedy",
  "And you're its only victim",
  "Distraught and heart-broken as you lose again",
  "Hiding behind curtain of authority",
  "Clinching a slice of the proverbial pie",
]

function getRandomLyric() {
  return lyrics[Math.floor(Math.random() * lyrics.length)]
}

function getCopyrightYear() {
  return new Date().getFullYear()
}

// interface FooterProps {}

// const Footer: React.FC<FooterProps> = (props: FooterProps) => (
const Footer = (props) => (
  <footer>
    <div>{getRandomLyric()}</div>
    <div>
      all content © {getCopyrightYear()} dichotic - {getDaysDead()} days dead
    </div>
  </footer>
)

export default Footer
